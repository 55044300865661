@font-face {
    font-family: 'Dela Gothic One';
    src: url('./fonts/DelaGothicOne-Regular.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Onest';
    src: url('./fonts/onest/Onest-VariableFont_wght.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Angry';
    src: url('./fonts/angry/Angry.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}