/* amoCRM Button */

#amobutton {
    background-color: #b97150 !important;
}

.amo-button-holder {
    right: 20px !important;
    bottom: 20px !important;
}

.amo-button--main-icon-inner path {
    fill: #f2d4b5 !important;
    stroke: #f2d4b5 !important;
}